import { DOCUMENT } from '@angular/common';
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { notShowNavbar } from './Helper/enums';
import { metaCities } from './Helper/constant';
import { WebEngageService } from './shared/services/webengage.service';
import { TrackingService } from './shared/services/tracking.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Stylework';
  navbarShow = true;
  constructor(
    @Inject(DOCUMENT) private dom,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private metaService: Meta,
    private webengageService:WebEngageService,
    private trackingService: TrackingService 
  ) {
  }
  ngOnInit(): void {
    const hostUrl = window.location.origin;
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe((event: NavigationEnd) => {
      this.updateCanonicalUrl(hostUrl + window.location.pathname);
      this.navbarSetup();

      const url = event.urlAfterRedirects;
      if (url === '/thank-you') {
        console.log('thank')
        this.trackingService.addFacebookPixel('632139899597490', 'Lead');
        this.trackingService.addGoogleTag('AW-11085169339', 'conversion_event_submit_lead_form_1');
      } else if (url === '/book-now') {
        this.trackingService.addFacebookPixel('632139899597490', 'PageView');
        this.trackingService.addGoogleTag('AW-11085169339');
      }
      const route = this.getChild(this.activatedRoute);
      route.params.subscribe(params => {
        const exist = metaCities.find((element) => element == params.citySlug);
        if (!exist) {
          route.data.subscribe(data => {
            let titleText = 'Stylework | Unconventional Coworking Spaces';
            if (Object.keys(data).length !== 0) {
              titleText = data?.title;
            }
            this.titleService.setTitle(titleText);
            if (data?.title) {
              this.metaService.updateTag({ property: data?.property?.ogTitle, content: data?.title });
            } else {
              this.metaService.removeTag('property= "og:title"');
            }
            if (data?.description) {
              this.metaService.updateTag({ name: data?.descriptionId, content: data?.description });
              this.metaService.updateTag({ property: data?.property?.ogDescription, content: data?.description });
            } else {
              this.metaService.removeTag('name= "description"');
              this.metaService.removeTag('property= "og:description"');
            }
            if (data?.keywords) {
              this.metaService.updateTag({ name: data?.keywordsId, content: data?.keywords });
            } else {
              this.metaService.removeTag('name= "keywords"');
            }
            if (data?.robots) {
              this.metaService.updateTag({ name: data?.robots, content: data?.noindex });
            } else {
              this.metaService.removeTag('name= "robots"');
            }
          });
        }
      });

    });
    this.initializeWebengage();
  }
  getChild(activatedRoute: ActivatedRoute): ActivatedRoute {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }
  onActivate(event): void {
    window.scrollTo({
      top: 0, // Scroll to the top of the page
      behavior: 'smooth' // Enables smooth scrolling
    });
  }

  navbarSetup(): void {
    const url = window.location.pathname.split('/')[1];
    if (notShowNavbar.includes(url)) {
      this.navbarShow = false;
    } else {
      this.navbarShow = true;
    }
  }

  updateCanonicalUrl(url: string) {
    const head = this.dom.getElementsByTagName('head')[0];
    var element: HTMLLinkElement = this.dom.querySelector(`link[rel='canonical']`) || null
    if (element == null) {
      element = this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel', 'canonical')
    element.setAttribute('href', url)
  }

  // initialize the webenage 
  initializeWebengage():void{
    this.webengageService.initialize();
    this.webengageService.trackEvent('landing page',{visit:window.location.pathname});
  }

  ngOnDestroy(): void {
    localStorage.removeItem('popularLocations');
    
  }
}
