import { Injectable, Inject, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class TrackingService {
  private renderer: Renderer2;
  private fbPixelInitialized = false;
  private gtagInitialized = false;

  constructor(
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  addFacebookPixel(pixelId: string, eventName: string) {
    const win = <any>window;

    if (!this.fbPixelInitialized) {
      const script = this.renderer.createElement('script');
      script.innerHTML = `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '${pixelId}');
      `;
      this.renderer.appendChild(this.document.head, script);

      const noscript = this.renderer.createElement('noscript');
      noscript.innerHTML = `
        <img height="1" width="1" style="display:none"
        src="https://www.facebook.com/tr?id=${pixelId}&ev=${eventName}&noscript=1" />
      `;
      this.renderer.appendChild(this.document.head, noscript);

      this.fbPixelInitialized = true;
    }

    // Always track event, even after init
    setTimeout(() => {
      if (win.fbq) {
        win.fbq('track', eventName);
      }
    }, 300);
  }

  addGoogleTag(gtagId: string, conversionEvent?: string) {
    const win = <any>window;
    win.dataLayer = win.dataLayer || [];

    if (!this.gtagInitialized) {
      const gtagScript = this.renderer.createElement('script');
      gtagScript.setAttribute('async', '');
      gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${gtagId}`;
      this.renderer.appendChild(this.document.head, gtagScript);

      const configScript = this.renderer.createElement('script');
      configScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${gtagId}');
      `;
      this.renderer.appendChild(this.document.head, configScript);

      this.gtagInitialized = true;
    }

    if (conversionEvent) {
      setTimeout(() => {
        win.dataLayer.push({
          event: conversionEvent,
          send_to: gtagId
        });
      }, 300);
    }
  }
}
